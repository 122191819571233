import React from 'react';
import { Layout,Dropdown,Carousel,Menu,Avatar,Row,Col } from 'antd'; 
import { Link,withRouter } from 'react-router-dom';
import './index.less';
import LogoPng from './../template1/images/V2/logo.png';
import { MostRequest } from "./../../utils/request";
const { Header, Content, Footer, Sider } = Layout;
const menu = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  return <Menu className="headMenu">
    <Menu.Item>
      <Link>
        {userInfo && userInfo.name}
      </Link>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item>
      <Link onClick={() => {
        window.localStorage.clear()
      }} replace to={'/login'}>
        <span style={{ fontWeight: 'normal' }}>退出</span>
      </Link>
    </Menu.Item>
  </Menu>
}; 
class Admin_layout extends React.Component{
    state = {
      menuList:[],
      selectedKey:0,
    }
    getList() {
      MostRequest.Post('/v1/sys/menu/query', {
  
      }).then((res) => {
        try {
          const { pathname } = this.props.location;
          const pathnameArr = pathname.split('/');
          const path = '/'+pathnameArr.splice(1,pathnameArr.length-1).join('/');
          console.log(path);
          const menuList = res.data.data[0]['children'][0]['children'];
          let selectedKey = 0;
          menuList.map((e,i)=>{
            if(e.url==path){
              selectedKey = i+'';
            }
          })
          this.setState({
            menuList:menuList,
            selectedKey:selectedKey
          })
        } catch (error) {
          console.log(error);
        }
        
      })
    }
    componentDidMount() {
      this.getList();
    }
    render(){
      const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
        return <Layout>
        <Sider
          style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
          }}
        >
          <div className="adminLogo" onClick={()=>{
            this.props.history.push('/');
          }}>
            <img src={LogoPng} alt="" />
            <p>北京二麦科技有限公司</p>
          </div>
          <Menu theme="dark" mode="inline" selectedKeys={[this.state.selectedKey]}>
            {
                this.state.menuList.map((e,i)=>{
                  return <Menu.Item key={i} onClick={({ item, key, keyPath, domEvent })=>{
                    let url = e.url;
                    const isDocument = e.url.indexOf('document')!==-1;
                    if (isDocument) {
                      url = `${url}?tag_id=${e.id}&sub_tag_id=&title=${encodeURIComponent(e.title)}`
                    } 
                    
                    
                    this.props.history.push(url);
                    this.setState({
                      selectedKey:key
                    })
                  }}>
                        {e.title}
                      </Menu.Item>
                   
                    
                
                })
            }
          </Menu>
        </Sider>
        <Layout className="site-layout" style={{ marginLeft: 200 }}>
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Row>
              <Col textAlign="right" span={4} offset={20}>
                <Row>
                  <Col span={10}>
                    <span style={{marginRight:'18px',color:"#808080"}}>
                      {userInfo.name} 
                    </span>
                  </Col>
                  <Col span={10}>
                    <div className="header_icon_list">
                      <Dropdown overlay={menu} placement="bottomRight">
                        <Avatar size={36} icon="user" src={userInfo.avatar}/>
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
                  
                 
              </Col>
            </Row>

          </Header>
          <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
            {
                this.props.children
            }
          </Content>
          <Footer style={{ textAlign: 'center' }}>
          Copyright © 2020-2022 北京二麦科技有限公司版权所有 | <a href="https://www.ermaisoft.com/">ermaisoft.com</a> |<a target="_blank" href="http://beian.miit.gov.cn/state/outPortal/loginPortal.action;jsessionid=e-hrAzhVBwMj9AiATNu_GbL5A5vKk9O3uKERx74WThdCnLWMXXEo!1958385134">京ICP备19054936号-4</a>
          </Footer>
        </Layout>
      </Layout>
    }
}

export default withRouter(Admin_layout);