import React from 'react';
import Header from "./header";
import Footer from "./footer";
import './index.less';
class Layout extends React.Component{
    render(){
        return <div className="">
        <Header />
        {
            this.props.children
        }
        <Footer />
    </div>
    }
}

export default Layout