import { asyncComponent } from './../../commonComponents/asyncComponent';
const HomePage = asyncComponent(() => import('./v2page/index/index'))
const AboutUs = asyncComponent(() => import('./v2page/aboutUs/index'))
const News = asyncComponent(() => import('./v2page/news/index'))
const Product = asyncComponent(() => import('./v2page/product/index'))
const Solution = asyncComponent(() => import('./v2page/solution/index'))
const NewsDetail = asyncComponent(() => import('./v2page/newsDetail/index'))
const fe_page = [
    {
        title: '首页',
        path: '/',
        component: HomePage,
        exact: true,
    },
    {
        title: '首页',
        path: '/FE/index',
        component: HomePage,
        exact: true,
    },
    {
        title: '解决方案',
        path: '/FE/solution',
        component: Solution,
        exact: true,
    },
    {
        title: '产品与服务',
        path: '/FE/product',
        component: Product,
        exact: true,
    },
    {
        title: '新闻详情',
        path: '/FE/newsDetail',
        component: NewsDetail,
        exact: true,
    },
    
    {
        title: '新闻动态',
        path: '/FE/news',
        component: News,
        exact: true,
    },
    {
        title: '关于我们',
        path: '/FE/aboutUs',
        component: AboutUs,
        exact: true,
    },
]
export default fe_page