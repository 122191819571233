import { Button, Checkbox, Input, message } from 'antd';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { CheckIsPhone } from '@/tools/tools';
import './index.less';
import { GetCheckNums, LoginsCode, LoginsPassword } from './request';
import { GetUrlParams } from '@/tools/tools';
import { branch } from '@/tools/common';
import {MostRequest} from '@/tools/request';
let loginObj = null;
class Login extends Component {
  state = {
    type: 1,
    qrLogin: false,
    checkTime: '获取验证码',
    canGetCheck: true,
    username: '',
    password: '',
    mobile: '',
    checkNum: '', 
    loginLoading: false,
  }
  componentDidMount () {
    const params = GetUrlParams();
    if (params.url) {
      this.url = decodeURIComponent(params.url)
    }
    const s = document.createElement('script')
      s.type = 'text/javascript'
      s.src = 'https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js'
      const redirect_uri = encodeURI(window.location.origin+'/scanCodeSuccess');
      const wxElement = document.body.appendChild(s)
      console.log(redirect_uri);
      wxElement.onload = function () {

        loginObj = new window.WxLogin({
          self_redirect:false,
          id:"qr_code", 
          appid: "wxd8384c8f86478995", 
          scope: "snsapi_login", 
          redirect_uri: redirect_uri,
          state: "1234567890",
          style: "black",
          href: window.location.origin+"/wxcss/wxlogin.css"
        })
      }
  }
  changeType = (type) => {
    this.setState({
      type
    })
  }
  login = () => {
    const { username, password, mobile, checkNum, type } = this.state;
    if (type === 1) {
      if (!mobile || !checkNum) {
        message.error('手机号或验证码不能为空');
        return false
      }
      this.setState({
        loginLoading: true
      })
      
      LoginsCode(mobile, checkNum).then(res => {
        // if (res.msg === 'ok') {
        //   this.loginIsOk()
        //   return
        // } 
        if(res.data.result==1){
          message.error(res.data.message);
        }else{
          this.loginIsOk()
        }
        this.setState({
          loginLoading: false
        });
      }).catch(e => {
        this.setState({
          loginLoading: false
        })
      })
    } else {
      if (!username || !password) {
        message.error('手机号或验证码不能为空');
        return false
      }
      this.setState({
        loginLoading: true
      })
      LoginsPassword(username, password).then(res => {
        if (res.msg === 'ok') {
          this.loginIsOk()
          return
        }
        this.setState({
          loginLoading: false
        })
      }).catch(e => {
        this.setState({
          loginLoading: false
        })
      })
    }

  }

  loginIsOk = () => {  
    localStorage.clear()
    MostRequest.Get('/v1/current').then(res => {
      localStorage.setItem('userInfo', JSON.stringify(res.data));
      setTimeout(() => {
        if (this.url) {
          window.location.href = this.url;
          } else { 
          this.props.history.replace('/')
        }
      }, 400);
     
    }).catch(e => {
      if (this.props.location.pathname !== '/login' && this.props.location.pathname !== '/'&&  this.props.location.pathname !== '/scanCodeSuccess' && this.props.location.pathname.indexOf('/newPartyBuild') === -1) {
        this.props.history.replace('/login')
        this.props.setLoading(false);
      } else {
        // this.props.getNavList();
      }
    })
    
  }

  getCheckNum = () => {
    const { mobile } = this.state;
    if (!mobile || this.hadSend) return false
    this.hadSend = true;
    this.setState({
      checkTime: '正在发送...'
    })
    GetCheckNums(mobile).then(res => {
      this._getCheckNum()
    }).catch(e => {
      this.hadSend = false;
      message.error(e, 2);
      this.setState({
        checkTime: '重新获取'
      })
    })
  }

  _getCheckNum = () => {
    let checkTime = 60;
    this.setState({
      checkTime: `${checkTime}s后重新获取`,
      canGetCheck: false
    })
    this.getCheckNumTime = setInterval(() => {
      checkTime--;
      this.setState({
        checkTime: `${checkTime}s后重新获取`
      })
      if (checkTime === 0) {
        this.hadSend = false;
        clearInterval(this.getCheckNumTime);
        this.setState({
          checkTime: `重新获取`,
          canGetCheck: true
        })
      }
    }, 1000)
  }

  componentWillUnmount () {
    this.getCheckNumTime && clearInterval(this.getCheckNumTime)
  }

  render () {
    const {
      type, qrLogin, checkTime,
      mobile, loginLoading,
      canGetCheck
    } = this.state;
    return (
      <div className="logins center_flex">
        <div className="login_title">
          <p>北京二麦科技有限公司</p>
          <span>让管理更科学</span>
        </div>
        <div className='login_container center_flex'>
          <div
            onClick={() => this.setState({ qrLogin: !qrLogin })}
            className="change_login_type">
            {
              qrLogin ?
                <img src={require('./images/phone_login.png').default} alt="" />
                : <img src={require('./images/code_login.png').default} alt="" />
            }

          </div>
          <div style={{ display: qrLogin ? 'none' : 'block' }} className="login_phone">
            <div className="code_login">
              手机验证码登录
            </div>
            {
              type === 0 ? <div className='input_msg'>
                <Input
                  onChange={e => this.setState({ username: e.target.value })} placeholder='请输入账号' />
                <Input
                  onChange={e => this.setState({ password: e.target.value })} type='password' placeholder='请输入密码' />
              </div>
                :
                <div className='input_msg'>
                  <Input
                    key='phone'
                    onChange={e => this.setState({ mobile: e.target.value })}
                    autoComplete="off"
                    placeholder='请输入手机号' />
                  <div className="check_num">
                    <Input
                      onChange={e => this.setState({ checkNum: e.target.value })}
                      placeholder='请输入验证码' />
                    <span
                      onClick={this.getCheckNum}
                      className={canGetCheck && CheckIsPhone(mobile)
                        ?
                        'can_click get_check_num'
                        : 'get_check_num'}>
                      {
                        checkTime
                      }
                    </span>
                  </div>
                </div>
            }
            <div className="auto_login">
              <div className="select_auto_login between_flex" style={{marginLeft:'3px'}}>
                <Checkbox>
                  <span className="auto_login_text">
                    5天内自动登录
                </span>
                </Checkbox>
                <span className="forgot_password">
                  忘记密码
                </span>
              </div>
            </div>

            <Button
              loading={loginLoading}
              onClick={this.login}
              className='login_btn'>
              登录
            </Button>
          </div>

          <div
            style={{ display: !qrLogin ? 'none' : 'block' }}
            className="login_right_qrcode">
            <div className="wechat_login">
              微信扫码登录
           </div>
            <div className="qr_code" id="qr_code">
              
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Login)

