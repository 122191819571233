import { asyncComponent } from './commonComponents/asyncComponent';

const BannerPage = asyncComponent(() => import('./Pages/Admins/banner/index'))
const FocusNews = asyncComponent(() => import('./Pages/Admins/mainPageEdit/focusNews'))
const HomeBanner = asyncComponent(() => import('./Pages/Admins/mainPageEdit//homeBanner'))
const DataBaseVideo = asyncComponent(() => import('./Pages/Admins/mainPageEdit/database/video/index'))
const AddFocusNews = asyncComponent(() => import('./Pages/Admins/mainPageEdit/focusNews/addFocusNEws'))
export const mainPageEdit = [
    {
        title: '首页banner',
        path: '/mainPageEdit/home/banner',
        component: HomeBanner,
        exact: true,
    },
    {
        title: '添加要闻',
        path: '/mainPageEdit/*/addDocuments',
        component: AddFocusNews,
        exact: true
}, 
{
    title: '视频',
    path: '/mainPageEdit/database/video/document',
    component: DataBaseVideo,
    exact: true
},
    {
        title: '要闻',
        path: '/mainPageEdit/*/document',
        component: FocusNews,
        //  修改一下  点击menu的时候  菜单title要存入redux里面
    }, 
    
    {
            title: '评论管理',
            //key: 'operationRecord',
            path: '/mainPageEdit/comment',
            component: Comment,
    },
   
]