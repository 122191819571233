import { MostRequest } from '@/tools/request';
export const GetCheckNums = (mobile) => {
  return new Promise((resolve, reject) => {
    MostRequest.Post('/v1/get_login_sms', { mobile }).then(res => {
      resolve(res)
    }).catch(e => {
      reject('发送失败，请重试')
    })
  })
}

export const LoginsCode = (mobile, code) => {
  return new Promise((resolve, reject) => {
    MostRequest.Post('/v1/login_sms', { mobile, code }).then(res => {
      resolve(res);
    }).catch(e => {
      reject(e) 
    })
  })
}
export const LoginsPassword = (usr, pwd) => {
  return new Promise((resolve, reject) => {
    MostRequest.Post('/v1/login', { usr, pwd }).then(res => {
      resolve(res)
    }).catch(e => {
      reject(e)
    })
  })
}