import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import LogoPng from './images/V2/logo.png';
class Footer extends React.Component{
    render(){
        return <div className="wrap footer">
        <div className="Htmlcontent footerContent">
            <div className="footerLeft">
                <div className="headerLogo">
                    <img src={LogoPng} alt="" />
                    <p>北京二麦科技有限公司</p>
                </div>
                <div className="btn" onClick={()=>{
                    window.location.href = 'https://www.ermaisoft.com/html/advisory.html';
                }}>获取方案</div>
            </div> 
            <div className="footerRight">
                <div className="footerRightItem">
                    <div className="footerRightTitle">解决方案</div>
                    <div className="footerRightList"><a>政务</a></div>
                    <div className="footerRightList"><a>卫生健康</a></div>
                    <div className="footerRightList"><a>教育</a></div>
                    <div className="footerRightList"><a>互联网</a></div>
                </div>
                <div className="footerRightItem">
                    <div className="footerRightTitle">产品与服务</div>
                    <div className="footerRightList"><a target="_blank" href="https://www.dangjianos.com/">智慧党建云平台系统</a></div>
                    <div className="footerRightList"><a target="_blank" href="https://www.emeast.com/pa/#/user/login">心理测评管理系统</a></div>
                    <div className="footerRightList"><a>心理测评宝</a></div>
                    <div className="footerRightList"><a target="_blank" href="https://redalert.dangjianos.com/#/">疫情防控预警系统</a></div>
                </div>
                <div className="footerRightItem">
                    <div className="footerRightTitle">新闻动态</div>
                    <div className="footerRightList"><Link to="/FE/news">新闻动态</Link></div>
                </div>
                <div className="footerRightItem">
                    <div className="footerRightTitle">关于我们</div>

                    <div className="footerRightList"><Link to="/FE/aboutUs">关于我们</Link></div>
                    <div className="footerRightList"><a href="https://www.ermaisoft.com/html/advisory.html">联系我们</a></div>
                </div>
            </div>
        </div>
        <div className="Copyright">
            <div>
            Copyright © 2020-2022 北京二麦科技有限公司版权所有 | <a href="https://www.ermaisoft.com/">ermaisoft.com</a> |<a target="_blank" href="http://beian.miit.gov.cn/state/outPortal/loginPortal.action;jsessionid=e-hrAzhVBwMj9AiATNu_GbL5A5vKk9O3uKERx74WThdCnLWMXXEo!1958385134">京ICP备19054936号-4</a>
            </div>
        </div>
    </div>
    }
}

export default Footer