/*@flow*/
import React, { Component } from 'react'
import { MyLifeCircle } from "./myRoute";
import { Spin, Alert } from 'antd';
export function asyncComponent (importComponent: any, params: Object = {}) {
	class AsyncComponent extends Component<any, any> {
		constructor(props: Object) {
			super(props);
			this.state = {
				component: null,
				visible: true
			}
		}

		async componentDidMount () {
			const { default: component } = await importComponent();
			this.setState({
				component: component
			});
		}

		render () {
			const C = this.state.component;


			return C ? <MyLifeCircle><C {...this.props} /></MyLifeCircle> :<div className="example" style={style}>
			<Spin>
				<Alert
				message="加载中"
				description="页面正在加载，请稍后"
				type="info"
				/>
			</Spin>
		  </div>
		}
	}

	return AsyncComponent
}
const style = {
	textAlign:'center',
	width:'100%',
	height:'100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}