import './App.less';
import React from 'react';
import { BrowserRouter,Switch, Route } from "react-router-dom"
import HomePage from './Pages/FE/home/index';
import Layout from "./layout/template1/layout";
import Admin_layout from "./layout/admin/index";
import fe_page from "./layout/template1/routerConfig";
import { mainPageEdit } from "./routesConfig";
import Login from './Pages/Admins/login/index';
let FERoutes_ = [];
let mainPageEditRoutes_ = [];
setRouteList(fe_page, FERoutes_);
setRouteList(mainPageEdit, mainPageEditRoutes_);
function FE_ROUTER(){
  return <Layout>
    <Switch>
      {FERoutes_}
    </Switch>
  </Layout>
}
function mainPageEdit_ROUTER(){
    return <Admin_layout>
      <Switch>
        {mainPageEditRoutes_}
      </Switch>
    </Admin_layout>
  }
class App extends React.Component{
  render(){
    console.log(FE_ROUTER)
    return <BrowserRouter>
        <Switch>
            <Route path={'/login'} key={'login'} exact component={Login} />
            <Route exact path="/" component={FE_ROUTER}></Route>
            <Route path="/FE" component={FE_ROUTER}></Route>
            <Route path="/mainPageEdit" component={mainPageEdit_ROUTER}></Route>
        </Switch>
    </BrowserRouter>
  }
}

export default App;


function setRouteList (arr, routeList) {
  arr.forEach(element => {
        if (element.children && element.children.length > 0 && element.type !== 'document') {
              element.children.forEach((ele, index) => {
                    routeList.push(
                          <Route
                                path={ele.path}
                                component={ele.component}
                                exact={ele.exact}
                                key={ele.key || index}
                          >
                          </Route>
                    );
              })
        } else if (element.component) {
              routeList.push(
                    <Route path={element.path} component={element.component} exact={element.exact}
                          key={element.path}>
                    </Route>
              );
        }
  });
}