import React from 'react';
import { Link, withRouter } from 'react-router-dom'
import LogoPng from './images/V2/logo.png';
import { Dropdown,Carousel,Menu } from "antd";
import { MostRequest } from "./../../utils/request";
const menu = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return <Menu className="headMenu">
      <Menu.Item>
        <Link>
          {userInfo && userInfo.name}
        </Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Link onClick={() => {
          window.localStorage.clear()
        }} replace to={'/login'}>
          <span style={{ fontWeight: 'normal' }}>退出</span>
        </Link>
      </Menu.Item>
    </Menu>
  }; 

let container = this;
class Header extends React.Component{
    constructor(props) {
        super(props);
        const pathname = props.location.pathname;
        this.state = {
          pathname,
          data: [],
          navList: props.navList ? props.navList : [],
          menuLists: props.menuLists,
        }
    }
    getList() {
        MostRequest.Get('/v1/banner/list', {
    
        }).then((res) => {
          this.setState({
            data: res.data ? res.data : [],
          })
        })
    }
      componentDidMount() {
        container = this;
        this.getList();
      }
    render(){
        const userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
        const { pathname } = this.props.location;
        const pathnameArr = pathname.split('/');
        const path = pathnameArr[pathnameArr.length - 1];
        return <div className="headerTop">
        <div className="headerTopBar Htmlcontent">
          <div className="headerLogo">
            <img src={LogoPng} alt="" />
            <p>北京二麦科技有限公司</p>
          </div>
          <div className="headerNav">
            <div className={path == 'index' || path == '' ? "headerNavItem checked" : "headerNavItem"}><Link to="/FE/index">首页</Link></div>
            <div className={path == 'solution' ? "headerNavItem checked" : "headerNavItem"}><Link to="/FE/solution">解决方案</Link></div>
            <div className={path == 'product' ? "headerNavItem checked" : "headerNavItem"}><Link to="/FE/product">产品与服务</Link></div>
            <div className={path == 'news' ? "headerNavItem checked" : "headerNavItem"}><Link to="/FE/news">新闻动态</Link></div>
            <div className={path == 'aboutUs' ? "headerNavItem checked" : "headerNavItem"}><Link to="/FE/aboutUs">关于我们</Link></div>
          </div>
          {
            userInfo ? <div className="headerLoginWrap">
              <div className="loginItem" onClick={() => {
                window.location.href = window.location.origin + '/mainPageEdit/home/banner'
              }}>工作台</div>
              <div className="loginLine"></div>
              <div className="loginItem">
                <Dropdown overlay={menu} placement="bottomRight">
                  <span>{userInfo.name}</span>
                </Dropdown>
              </div>
            </div> : <div className="headerLoginWrap">
                <div className="loginItem"></div>
                {/* <div className="loginLine"></div> */}
                <div className="loginItem" onClick={() => {
                  window.location.href = window.location.origin + '/login'
                }}>登录</div>
              </div>
          }

        </div>
        {/* <div className="headerDes">
          <div>1+1+N整体解决方案</div>
          <div>开启智慧城市美好未来</div>
      </div> */}
        <div className="headerBanner">
          <Carousel afterChange={this.onChange}>
            {
              this.state.data.map((e, i) => {
                return <div key={i} className="bannerwwrap">
                  <img src={e.url} />
                  <div className="headerDes">
                    <div>{e.title}</div>
                  </div>
                </div>
              })
            }


          </Carousel>
        </div>
      </div>
    }
}

export default withRouter(Header)
